function confirmBox(vue,text,jump){
    vue.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        // this.$message({
        //     type: 'success',
        //     message: '删除成功!'
        // });
        vue.$router.push(jump)
    }).catch(() => {
        // this.$message({
        //     type: 'info',
        //     message: '已取消删除'
        // });          
    });
}

function wrongTip(vue,text,type,isDelay){
    vue.$confirm(text, '提示', {
        confirmButtonText: '确定',
        showClose:false,
        showCancelButton:false,
        type: type||'warning'
    }).then(() => {
        // this.$message({
        //     type: 'success',
        //     message: '删除成功!'
        // });
        // vue.$router.push(jump)
    })

    if(isDelay){
        setTimeout(()=>{
            document.body.removeChild(document.querySelector(".el-message-box__wrapper"))
            document.body.removeChild(document.querySelector(".v-modal"))
        },1500)
    }
}

// 验证手机号
function regTelephone(phone){
    var reg=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if(reg.test(phone)){
        return true
    }else{
        return false
    }
}

export default{
    confirmBox,wrongTip,regTelephone
}