import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/merchandise',
    name: 'merchandise',
    component: ()=>import("@/views/Merchandise"),
    meta:{
      pageName:"商品"
    }
  },
  {
    path: '/furnitureDetail',
    name: 'furnitureDetail',
    component: ()=>import("@/views/FurnitureDetail"),
    meta:{
      pageName:"家具详情"
    }
  },
  {
    path: '/couture',//高定专栏
    name: 'couture',
    component: ()=>import("@/views/Couture"),
    meta:{
      pageName:"高定专栏"
    }
  },
  {
    path: '/combo',//甄选套餐
    name: 'combo',
    component: ()=>import("@/views/Combo"),
    meta:{
      pageName:"甄选套餐"
    }
  },
  {
    path: '/comboDetail',//套餐详情
    name: 'comboDetail',
    component: ()=>import("@/views/ComboDetail"),
    meta:{
      pageName:"套餐详情"
    }
  },
  {
    path: '/wishMatch',//随心配
    name: 'wishMatch',
    component: ()=>import("@/views/WishMatch"),
    meta:{
      pageName:"随心配"
    }
  },
  {
    path: '/wishMatch_clear',//随心配-结算
    name: 'wishMatch_clear',
    component: ()=>import("@/views/WishMatch_clear"),
    meta:{
      pageName:"随心配"
    }
  },
  {
    path: '/case',//案例
    name: 'case',
    component: ()=>import("@/views/Case"),
    meta:{
      pageName:"案例"
    }
  },
  {
    path: '/caseDetail',//案例详情
    name: 'caseDetail',
    component: ()=>import("@/views/CaseDetail"),
    meta:{
      pageName:"案例详情"
    }
  },
  {
    path: '/brandfest',//品牌馆
    name: 'brandfest',
    component: ()=>import("@/views/Brandfest"),
    meta:{
      pageName:"品牌馆"
    }
  },
  {
    path: '/searchResult',//搜索结果
    name: 'searchResult',
    component: ()=>import("@/views/SearchResult"),
    meta:{
      pageName:"搜索结果"
    }
  },
  {
    path: '/myShoppingCart',//我的购物车
    name: 'myShoppingCart',
    component: ()=>import("@/views/MyShoppingCart"),
    meta:{
      pageName:"购物车"
    }
  },
  {
    path: '/league',//招商加盟
    name: 'league',
    component: ()=>import("@/views/League"),
    meta:{
      pageName:"加盟"
    }
  },
  {
    path: '/personal',//个人
    name: 'personal',
    component: ()=>import("@/views/Personal"),
    meta:{
      pageName:"个人"
    },
    redirect:"/personal/center",
    children:[
      {
        path: 'center',//个人中心
        name: 'center',
        component: ()=>import("@/views/personalPage/Center"),
        meta:{
          pageName:"个人中心"
        }
      },
      {
        path: 'address',//收货地址
        name: 'address',
        component: ()=>import("@/views/personalPage/Address"),
        meta:{
          pageName:"我的收货地址"
        }
      },
      {
        path: 'subaccount',//子账号管理
        name: 'subaccount',
        component: ()=>import("@/views/personalPage/Subaccount"),
        meta:{
          pageName:"子账号管理"
        }
      },
      {
        path: 'myOrder',//我的订单
        name: 'myOrder',
        component: ()=>import("@/views/personalPage/MyOrder"),
        meta:{
          pageName:"我的订单"
        }
      },
      {
        path: 'orderDetail',//订单详情
        name: 'orderDetail',
        component: ()=>import("@/views/personalPage/OrderDetail"),
        meta:{
          pageName:"订单详情"
        }
      },
      
      {
        path: 'myAfterSale',//我的售后
        name: 'myAfterSale',
        component: ()=>import("@/views/personalPage/MyAfterSale"),
        meta:{
          pageName:"我的售后"
        }
      },
      {
        path: 'afterSaleDetail',//售后订单详情
        name: 'afterSaleDetail',
        component: ()=>import("@/views/personalPage/AfterSaleDetail"),
        meta:{
          pageName:"售后订单详情"
        }
      },
      {
        path: 'myShoppingCar',//我的购物车
        name: 'myShoppingCar',
        component: ()=>import("@/views/personalPage/MyShoppingCart"),
        meta:{
          pageName:"我的购物车"
        }
      },
      {
        path: 'footprint',//足迹
        name: 'footprint',
        component: ()=>import("@/views/personalPage/Footprint"),
        meta:{
          pageName:"足迹"
        }
      },
      {
        path: 'myMessage',//我的消息
        name: 'myMessage',
        component: ()=>import("@/views/personalPage/MyMessage"),
        meta:{
          pageName:"我的消息"
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import("@/views/Login")
  },
  {
    path: '/register',
    name: 'register',
    component: ()=>import("@/views/Register")
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: ()=>import("@/views/ForgetPwd")
  },
  {
    path: '/houseCombo',//全屋套餐
    name: 'houseCombo',
    component: ()=>import("@/views/HouseCombo"),
    meta:{
      pageName:"全屋套餐"
    }
  },
  {
    path: '/houseComboDetail',//全屋套餐详情
    name: 'houseComboDetail',
    component: ()=>import("@/views/HouseComboDetail"),
    meta:{
      pageName:"全屋套餐详情"
    }
  },
  {
    path: '/smartHome',//智慧家居
    name: 'smartHome',
    component: ()=>import("@/views/SmartHome"),
    meta:{
      pageName:"智慧家居"
    }
  },
  {
    path: '/buildingMaterial',//建材馆
    name: 'buildingMaterial',
    component: ()=>import("@/views/BuildingMaterial"),
    meta:{
      pageName:"建材馆"
    }
  },
  {
    path: '/previewDetail',//打印预览
    name: 'previewDetail',
    component: ()=>import("@/views/personalPage/PreviewDetail"),
    meta:{
      pageName:"预览"
    }
  },
  {
    path: '/payPage',//付款页面
    name: 'payPage',
    component: ()=>import("@/views/PayPage"),
    meta:{
      pageName:"付款"
    }
  },
]

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {  
  // console.log("重写push",location)
  var isPersonal=window.location.href.indexOf('/personal/')==-1?false:true
  if(typeof location=='string'){
    location={
      path:location
    }
  }
  if((isPersonal&&location.path!='/payPage')||location.notNew){
    // location.path='/personal/'+location.path
    return originalPush.call(this, location).catch(err => err)
  }
  var href=window.location.href.substring(0,window.location.href.indexOf("/#/")+2);
  var queryStr='';
  if(location.query){
    queryStr+='?'
    for(var key in location.query){
      queryStr+=`${key}=${location.query[key]}&`
    }
  }
  if(location.params){
    sessionStorage.wishComboName=location.params.comboName
  }
  // console.log(href)
  window.open(href+(location.path||location.name)+queryStr,"")

  // return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
  routes
})

// router.beforeEach((to,from,next)=>{
//   console.log(to)
//   console.log(from)
//   if(!localStorage.HPUserInfo){
//     if(to.name!='login'){
//       next("/login")
//     }else{
//       next()
//     }
//   }else{
//     next()
//   }
// })

export default router
