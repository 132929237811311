<template>
    <div class="footerComp">
        <div class="centerWrapper steps">
            <!-- <img src="../assets/imgs/flowShow.png"/> -->
        </div>
        <div class="foot">
            <!-- <img src="../assets/imgs/advantageShow.png"/> -->
            <div class="advantage centerWrapper">
                <div class="item">
                    <img src="../assets/imgs/effect17.png" />
                    <span>全屋商品 一站购齐</span>
                </div>
                <div class="item">
                    <img src="../assets/imgs/effect18.png" />
                    <span>优质配送 快速抵达</span>
                </div>
                <div class="item">
                    <img src="../assets/imgs/effect19.png" />
                    <span>大牌厂家 极致服务</span>
                </div>
                <div class="item">
                    <img src="../assets/imgs/effect20.png" />
                    <span>正品低价 售后无忧</span>
                </div>
            </div>
            <div class="bottomCopyright">
                <div>Copyright @2019-2024{{websiteName}}</div>
                <div>
                    <span>版权所有，并保留所有权利。</span>
                    <span @click="toFiling" style="cursor:pointer">蜀ICP备2024081010号</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                websiteName:""
            }
        },
        methods: {
            toFiling(){
                location.href="https://beian.miit.gov.cn"
            }
        },
        created() {
            this.$bus.$on("gettingConfig",()=>{
                this.websiteName=sessionStorage.websiteName
            })
        },
    }
</script>

<style lang="less" scoped>
.footerComp{
    margin-top: 100px;
    .steps{
        img{
            width: 100%;
        }
    }
    .foot{
        margin-top: 60px;
        .advantage{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item{
                display: flex;
                align-items: center;
                &>img{
                    width: 58px;
                    height: 58px;
                }
                &>span{
                    font-size: 26px;
                    font-weight: 500;
                    color: rgba(51, 51, 51, 1);
                    margin-left: 18px;
                }
            }
        }
        .bottomCopyright{
            height: 78px;
            margin-top: 47px;
            background: rgba(0, 0, 0, 1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            &>div:nth-child(1){
                font-size: 14px;
                font-weight: 500;
                color: rgba(204, 204, 204, 1);
                text-align: center;
            }
            &>div:nth-child(2){
                font-size: 14px;
                font-weight: 500;
                color: rgba(204, 204, 204, 1);
                text-align: center;
                margin-top: 5px;
                &>span:nth-child(2){
                    color: rgba(114, 114, 114, 1);
                }
            }
        }
    }
}
</style>