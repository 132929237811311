<template>
  <div class="home">
	  <headComp></headComp>
	  <liveCardComp></liveCardComp>
	  <div class="banner" @mousemove="mouseoutInner" @mouseout="outInner">
		  <div class="place" v-show="homeBannerList.length==0">
			  加载中...
		  </div>
		  <el-carousel class="homeBanner" :interval="3500" :autoplay="true" v-if="homeBannerList.length!=0">
				<el-carousel-item v-for="item in homeBannerList" :key="item.sort" @click.native="clickBannerImg(item.linkUrl)">
					<img :style="{'cursor':item.linkUrl?'pointer':''}" :src="item.bannerImgUrl"/>
				</el-carousel-item>
			</el-carousel>
		  <div class="inner" >
			  <div class="bigKind">
				  <div :style="{'height':100/goodsTypeList.length+'%'}" :class="{'line':1,'active':activeIndex==item.id,'wrap':goodsTypeList.length<=7}" v-for="(item,i) in goodsTypeList" :key="i" @mouseover="hoverIndex=i" @mouseenter="enterLine(item)">
					  <!-- <span class="overName" :class="{'active':activeIndex==item.id}" @mouseenter="clickLevelOne(item,i)" @click="jumpToSearchOne(item)">{{item.label}}</span>
					  <span :class="{'active':activeIndex2==child.id}" v-if="j<3" v-for="(child,j) in item.children" :key="j" @mouseenter="clickLevelTwo(child,j)" @click="jumpToSearchTwo(child)">{{child.label}}</span> -->
					  <span class="overName" @click="jumpToSearchOne(item)">{{item.label}}</span>
					  <span :class="{'active':activeIndex2==child.id,'placehold':!child.id}" v-if="j<item.showNum&&goodsTypeList.length>7" v-for="(child,j) in item.children" :key="j" @click="jumpToSearchTwo(child,item)">{{child.label}}</span>
					  <!-- <span>茶几</span>
					  <span>电视柜</span>
					  <span>休闲椅</span> -->

					  <div v-if="goodsTypeList.length<7" class="wrapLine">
						  <span :class="{'active':activeIndex2==child.id,'placehold':!child.id}" v-if="j<item.showNum" v-for="(child,j) in item.children" :key="j" @click="jumpToSearchTwo(child,item)">{{child.label}}</span>
					  </div>
				  </div>
			  </div>
			  <div class="kind" v-show="isShowChild">
				  <div class="subKind">
					  <div class="plateName">
						  <span>{{showTypeName}}</span>
						  <img src="../assets/imgs/moreArrow.png"/>
					  </div>
					  <div class="contains">
						  <div class="containsInner">
								<div class="item" v-for="(item,i) in showTypeChildren" :key="i" @click="clickLevelTwo(item,i)">
									<div class="pic" v-if="false">
										<!-- <img src="../assets/imgs/place2.png"/> -->
										<img :src="item.mainImages"/>
									</div>
									<img class="pic" :src="item.mainImages?item.mainImages:item.defaultUrl"/>
									<p>{{item.goodsTypeName||item.label}}</p>
								</div>
						  </div>
					  </div>
				  </div>
				  <div class="starBrand">
					  <div class="plateName">
						  <span>明星品牌</span>
						  <img src="../assets/imgs/moreArrow.png"/>
					  </div>
					  <div class="contains">
						  <div class="containsInner">
								<div class="item" v-for="(item,i) in brandList" :key="i" @click="clickStarBrand(item)">
									<!-- <img src="../assets/imgs/place3.png"/> -->
									<img :src="item.brandLogo?item.brandLogo:(item.cover?item.cover:item.defaultUrl)"/>
									<div class="text">{{item.brandName}}</div>
								</div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	  <div class="prefecture">
		  <div class="area">
			  <div class="centerWrapper">
				  <div class="nameLine">
					  <div class="divider_l themeBackColor"></div>
					  <div class="areaName">
							<img class="effect" src="../assets/imgs/effect1.png"/>
							<span>
								<span class="themeTextColor">高定</span>
								专区
							</span>
							<i class="bottomline"></i>
							<i class="circle themeCircleColor"></i>
						</div>
						<div class="divider_r themeBackColor"></div>
				  </div>
			  </div>
			  <div class="centerWrapper">
				  <div class="tabLine">
					  <div class="tabs">
						  <div :class="{'active':coutureGoodsTypeValue==item.dictValue}" v-for="(item,i) in coutureGoodsTypeList" :key="i" @click="clickCoutureType(item)">
							  {{item.dictLabel}}
							  <div class="border_effect">
								  <div class="border_top"></div>
								  <div class="border_right"></div>
								  <div class="border_bottom"></div>
								  <div class="border_left"></div>
							  </div>
						  </div>
					  </div>
					  <div class="more" @click="$router.push('/couture')">
						  <!-- <img src="../assets/imgs/more1.png"/> -->
						  更多
						  <i class="el-icon-arrow-right"></i>
					  </div>
				  </div>
			  </div>
			  <div class="couture">
				  <div class="list centerWrapper">
					<img class="leftClick" src="../assets/imgs/leftArrow.png" :style="{'filter':couturePage>1?'brightness(0.5)':'brightness(1)'}" v-show="coutureGoodsList.length>4" @click="clickCoutureSwi(1)"/>
					<img class="rightClick" src="../assets/imgs/rightArrow.png" :style="{'filter':couturePage<4?'brightness(0.5)':'brightness(1)'}" v-show="coutureGoodsList.length>4" @click="clickCoutureSwi(2)"/>
					<div class="listInner">
						<div class="card340_394" v-for="(item,i) in coutureGoodsList" :key="i" @click="clickCoutureGoods(item)">
							<div class="img">
								<img :src="item.cover?item.cover.split(',')[0]:require('../assets/imgs/place4.png')"/>
							</div>
							<div class="text">
								<p>{{item.name}}</p>
								<div class="price">
									<span>￥</span>{{item.ourShopPrice}}
								</div>
							</div>
						</div>
					</div>
				  </div>
				  
			  </div>
		  </div>
		  <div class="area">
			  <div class="centerWrapper">
				  <div class="nameLine">
					  <div class="divider_l themeBackColor"></div>
					  <div class="areaName">
							<img class="effect right" src="../assets/imgs/effect1.png"/>
							<span>
								<span class="themeTextColor">品牌</span>
								上新
							</span>
							<i class="bottomline"></i>
							<i class="circle themeCircleColor"></i>
						</div>
						<div class="divider_r themeBackColor"></div>
				  </div>
			  </div>
			  <div class="brand centerWrapper">
				  <div class="item" v-for="(item,i) in brandNewlyList" :key="i" :style="{'background-image':'url('+''+')'}" @click="clickStarBrand(item)">
					  <img class="back" :src="item.cover?item.cover:require('../assets/imgs/place5.png')"/>
					  <img class="logo" :src="item.brandLogo?item.brandLogo:require('../assets/imgs/place6.png')"/>
				  </div>
			  </div>
		  </div>
		  <div class="area">
			  <div class="customization themeHouseBack">
					<div class="centerWrapper">
						<div class="nameLine">
							<div class="divider_l themeBackColor"></div>
							<div class="areaName">
									<img class="effect" src="../assets/imgs/effect1.png"/>
									<span>
										<span class="themeTextColor">全屋</span>
										定制
									</span>
									<i class="bottomline"></i>
									<i class="circle themeCircleColor"></i>
								</div>
								<div class="divider_r themeBackColor"></div>
						</div>
					</div>
				  <div class="centerWrapper">
					  <div class="tabs">
						  <div :class="{'active':houseSpaceTypeValue==item.dictValue}" v-for="(item,i) in spaceTypeList" :key="i" @click="clickHouseSpace(item)">
							  {{item.dictLabel}}装修
							  <div class="border_effect">
								  <div class="border_top"></div>
								  <div class="border_right"></div>
								  <div class="border_bottom"></div>
								  <div class="border_left"></div>
							  </div>
							</div>
					  </div>
					  <div class="showArea">
						  <div class="flipster" v-if="housePackageList.length>0">
								<ul v-if="false">
									<li>
										<a href="#" class="Button Block">
											<h1>All Features</h1>
											<p>Showcase of all available features in both the Coverflow and Carousel styles</p>
										</a>
									</li>
									<li>
										<a href="#" class="Button Block">
											<h1>Basic Setup</h1>
											<p>The bare minimum code needed to implement Flipster</p>
										</a>
									</li>
									<li>
										<a href="#" class="Button Block">
											<h1>Carousel</h1>
											<p>Roundabout carousel style!</p>
										</a>
									</li>
									<li>
										<a href="#" class="Button Block">
											<h1>Coverflow with Tab Navigation</h1>
											<p>Example of tab navigation using the <code>enableNav</code> option</p>
										</a>
									</li>
									<li>
										<a href="#" class="Button Block">
											<h1>Carousel with Tab Navigation</h1>
											<p>Example of tab navigation using the <code>enableNav</code> option</p>
										</a>
									</li>
								</ul>

								<ul>
									<!-- <li v-if="housePackageList.length>1" @click="clickHousePackage(housePackageList[1])"> -->
									<li v-if="housePackageList.length>1" :data-packid="housePackageList[1].housePackageId">
										<a href="javascript:void(0)" class="Button Block">
											<img :src="housePackageList[1].cover?housePackageList[1].cover.split(',')[0]:require('../assets/imgs/place39.png')"/>
										</a>
										<div class="_overlay"></div>
										<i class="el-icon-arrow-right"></i>
									</li>
									<!-- <li v-if="housePackageList.length>0" @click="clickHousePackage(housePackageList[0])"> -->
									<li v-if="housePackageList.length>0" :data-packid="housePackageList[0].housePackageId">
										<a href="javascript:void(0)" class="Button Block">
											<img :src="housePackageList[0].cover?housePackageList[0].cover.split(',')[0]:require('../assets/imgs/place39.png')"/>
										</a>
										<div class="_overlay"></div>
										<i class="el-icon-arrow-right"></i>
									</li>
									<!-- <li v-if="housePackageList.length>2" @click="clickHousePackage(housePackageList[2])"> -->
									<li v-if="housePackageList.length>2" :data-packid="housePackageList[2].housePackageId">
										<a href="javascript:void(0)" class="Button Block">
											<img :src="housePackageList[2].cover?housePackageList[2].cover.split(',')[0]:require('../assets/imgs/place39.png')"/>
										</a>
										<div class="_overlay"></div>
										<i class="el-icon-arrow-right"></i>
									</li>
								</ul>
							</div>
							<!-- <div class="goodsEmpty">
								<img src="../assets/imgs/goodsEmpty.png"/>
								<div>店家正在努力上新中~</div>
							</div> -->
					  </div>
					  <div class="btns">
						  <el-button class="wantTo" @click="wantToCustom">我要定制<i class="el-icon-arrow-right el-icon--right"></i></el-button>
						  <el-button class="knowMore" @click="$router.push('/houseCombo')">了解更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
					  </div>
				  </div>
			  </div>
		  </div>
		  <div class="area">
			  <div class="centerWrapper">
				  <div class="nameLine">
					  <div class="divider_l themeBackColor"></div>
					  <div class="areaName">
							<img class="effect right" src="../assets/imgs/effect1.png"/>
							<span>
								<span class="themeTextColor">优选</span>
								套餐
							</span>
							<i class="bottomline"></i>
							<i class="circle themeCircleColor"></i>
						</div>
						<div class="divider_r themeBackColor"></div>
				  </div>
			  </div>
			  <div class="centerWrapper">
				  <div class="tabLine">
					  <div class="tabs">
						  <div :class="{'active':packageSpaceTypeValue==item.dictValue}" v-for="(item,i) in spaceTypeList" :key="i" @click="clickPackageSpaceType(item)">
							  {{item.dictLabel}}
							  <div class="border_effect">
								  <div class="border_top"></div>
								  <div class="border_right"></div>
								  <div class="border_bottom"></div>
								  <div class="border_left"></div>
							  </div>							  
							</div>
					  </div>
					  <div class="more" @click="$router.push('/combo')">
						  <!-- <img src="../assets/imgs/more1.png"/> -->
						  更多
						  <i class="el-icon-arrow-right"></i>
					  </div>
				  </div>
			  </div>
			  <div class="pack centerWrapper" :style="goodsPackageList.length>2?{'grid-template-rows':pxToRem(466)+'rem '+pxToRem(466)+'rem'}:{}">
				  <div class="item" v-for="(item,i) in goodsPackageList" :key="i" @click="clickPreferredPackage(item)">
					  <!-- <img src="../assets/imgs/place7.png"/> -->
					  <!-- <img :src="item.cover?item.cover:require('../assets/imgs/place7.png')"/> -->
					  <div class="img">
						  <img :src="item.cover?item.cover:require('../assets/imgs/place7.png')"/>
					  </div>
					  <div class="text">
						  <p>{{item.goodsPackageName}}</p>
						  <p><span>￥</span>{{item.totalPrice}}</p>
					  </div>
				  </div>
				  <!-- <div class="item">
					  <img src="../assets/imgs/place7.png"/>
					  <div class="text">
						  <p>极简风 全包布艺沙发 高档天然棉麻面料 组合沙发</p>
						  <p><span>￥</span>15099.00</p>
					  </div>
				  </div> -->
			  </div>
		  </div>
		  <div class="area bk">
			  <div class="centerWrapper">
				  <div class="nameLine">
					  <div class="divider_l themeBackColor"></div>
					  <div class="areaName">
							<img class="effect" src="../assets/imgs/effect1.png"/>
							<span>
								<span class="themeTextColor">优选</span>
								家具
							</span>
							<i class="bottomline"></i>
							<i class="circle themeCircleColor"></i>
						</div>
						<div class="divider_r themeBackColor"></div>
				  </div>
			  </div>
			  <div class="centerWrapper">
				  <div class="tabLine">
					  <div class="tabs">
						  <div :class="{'active':preferredType==item.value}" v-for="(item,i) in preferredGoodsSelect" :key="i" @click="clickPreferredGoodsType(item)">
							  {{item.label}}
							  <div class="border_effect">
								  <div class="border_top"></div>
								  <div class="border_right"></div>
								  <div class="border_bottom"></div>
								  <div class="border_left"></div>
							  </div>
							</div>
					  </div>
					  <div class="more" @click="clickMorePreferred">
						  <!-- <img src="../assets/imgs/more1.png"/> -->
						  更多
						  <i class="el-icon-arrow-right"></i>
					  </div>
				  </div>
			  </div>
			  <div class="furniture">
				  <div class="list centerWrapper">
					<!-- <img class="leftClick" src="../assets/imgs/leftArrow.png"/>
					<img class="rightClick" src="../assets/imgs/rightArrow.png"/> -->
					<div class="listInner">
						<div class="card340_394" v-for="(item,i) in preferredGoodsList" :key="i" @click="clickPreferredGoods(item)">
							<!-- <img src="../assets/imgs/place4.png"/> -->
							<div class="img">
								<img :src="item.cover?item.cover:require('../assets/imgs/place4.png')"/>
							</div>
							<div class="text">
								<p>{{item.name}}</p>
								<div class="price">
									<span>￥</span>{{item.ourShopPrice}}
								</div>
							</div>
						</div>
					</div>
				  </div>
				  
			  </div>
		  </div>
		  <div class="area">
			  <div class="centerWrapper">
				  <div class="nameLine">
					  <div class="divider_l themeBackColor"></div>
					  <div class="areaName">
							<img class="effect right" src="../assets/imgs/effect1.png"/>
							<span>
								<span class="themeTextColor">热门</span>
								买家秀
							</span>
							<i class="bottomline"></i>
							<i class="circle themeCircleColor"></i>
						</div>
						<div class="divider_r themeBackColor"></div>
				  </div>
			  </div>
			  <div class="centerWrapper">
				  <div class="tabLine">
					  <div></div>
					  <div class="more" @click="$router.push('/case')">
						  <!-- <img src="../assets/imgs/more1.png"/> -->
						  更多
						  <i class="el-icon-arrow-right"></i>
					  </div>
				  </div>
			  </div>
			  <div class="buyersShow centerWrapper" v-show="hotBuyersShowLlist.length>0" :style="hotBuyersShowLlist.length>3?{'grid-template-rows':'473px 473px'}:{}">
				  <div class="item" v-for="(item,i) in hotBuyersShowLlist" :key="i" @click="clickCase(item)">
					  <!-- <img src="../assets/imgs/place8.png"/>
					  <div class="text">
						  <p>23.8m² | 二居 | 6.5万</p>
						  <p>北欧传统风格客厅</p>
					  </div> -->
					  <div class="img">
					  		<img :src="item.images[0].url"/>
							<div class="user" v-if="item.userInfo">
								<img :src="item.userInfo.avatar"/>
								<span>{{item.userInfo.nickName||item.userInfo.telephone}}</span>
							</div>
					  </div>
					  <div class="text">
						  <p>{{item.content}}</p>
					  </div>
				  </div>
			  </div>
			</div>
			<div class="area bk">
			  <div class="centerWrapper">
				  <div class="nameLine">
					  <div class="divider_l themeBackColor"></div>
					  <div class="areaName">
							<img class="effect" src="../assets/imgs/effect1.png"/>
							<span>
								<span class="themeTextColor">加盟</span>
								品牌
							</span>
							<i class="bottomline"></i>
							<i class="circle themeCircleColor"></i>
						</div>
						<div class="divider_r themeBackColor"></div>
				  </div>
			  </div>
			  <div class="centerWrapper">
				  <div class="tabLine">
					  <div></div>
					  <div class="more" @click="$router.push('/brandfest')">
						  <!-- <img src="../assets/imgs/more1.png"/> -->
						  更多
						  <i class="el-icon-arrow-right"></i>
					  </div>
				  </div>
			  </div>
			  <div class="joinedBrand">
				  <div class="list centerWrapper">
					<img class="leftClick" src="../assets/imgs/leftArrow.png" v-show="joinedBrandList.length>4"/>
					<img class="rightClick" src="../assets/imgs/rightArrow.png" v-show="joinedBrandList.length>4"/>
					<div class="listInner">
						<div class="item" v-for="(item,i) in joinedBrandList" :key="i" @click="clickStarBrand(item)">
							<!-- <img class="cover" src="../assets/imgs/place9.png"/>
							<img class="logo" src="../assets/imgs/place10.png"/> -->
							<!-- <img class="cover" :src="item.cover?item.cover:require('../assets/imgs/place9.png')"/> -->
							<div class="cover">
								<img :src="item.cover?item.cover:item.defaultUrl"/>
							</div>
							<img class="logo" :src="item.brandLogo?item.brandLogo:(item.defaultUrl)"/>
						</div>
					</div>
				  </div>
			  </div>
			</div>
		  	
	  </div>
	  <footerComp></footerComp>
	  
  </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import liveCardComp from "@/components/liveCardComp.vue"
import {getGoodsTypeTree,getGoodsTypeListChild,getBrandList,getBrandPageList,
queryGoodsPackageList,getBuyersShowList__,getPreferredGoodsSelect,getPreferredGoodsList,
getBrandList__,getDictTypeList,getHousePackagePreferred,getCoutureGoodsList,getWebsiteSetting} from "@/axios/api"
export default {
	name: 'Home',
	components: {
		headComp,footerComp,liveCardComp
	},
	data(){
		return{
			websiteLogo:"",
			defaultActive:"",
			activeIndex:'',
			activeIndex2:"",
			hoverIndex:1,
			goodsTypeList:[],
			showTypeName:"",
			showTypeChildren:[],
			brandList:[],
			coutureGoodsTypeList:[],//高定专区商品类型列表
			coutureGoodsTypeValue:"",//高定专区商品类型
			coutureGoodsList:[],//高定专区商品列表
			couturePage:1,
			coutureSize:4,
			spaceTypeList:[
				// {
				// 	label:"客厅",
				// 	value:1,
				// },
				// {
				// 	label:"卧室",
				// 	value:2,
				// },
				// {
				// 	label:"餐厅",
				// 	value:3,
				// },
				// {
				// 	label:"儿童房",
				// 	value:4,
				// },
				// {
				// 	label:"书房",
				// 	value:5,
				// }
			],
			packageSpaceTypeValue:"",
			houseSpaceTypeValue:"",
			housePackageList:[],//全屋定制列表
			brandNewlyList:[],//品牌上新列表
			goodsPackageList:[],//优选套餐列表
			preferredGoodsSelect:[],//优选家具筛选列表
			preferredType:"",//优选家具选定类型
			preferredGoodsList:[],//优选家具列表
			hotBuyersShowLlist:[],//热门买家秀列表
			joinedBrandList:[],//加盟品牌列表
			
			homeBannerList:[],//首页banner列表
			isShowChild:false,//是否显示子分类及品牌
		}
	},
	methods: {
		pxToRem(v){
            return Math.round(v/16*100000)/100000
        },
		// 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				host:location.host,
				// host:"www.i-dian.com",
				// host:"https://bus.i-dian.com/"
			}).then(res=>{
				console.log("网站配置",res);
				if(res.code==200){
					if(res.data.websiteLogo){
						this.websiteLogo=res.data.websiteLogo;
					}
					if(res.data.bannerPlaceList){
						res.data.bannerPlaceList.forEach(item=>{
							if(item.bannerPlace==1){
								this.homeBannerList=item.bannerSettingList
							}
						})
					}
				}
			})
		},
		clickBannerImg(url){
			// console.log(url)
			if(url){
				window.open(url,'_blank')
			}
		},
		// 商品分类
		gainGoodsTypeTree(){
			getGoodsTypeTree().then(res=>{
				console.log("商品分类树",res);
				if(res.code==200){
					var widthVal=this.pxToRem(356)*parseFloat(document.documentElement.style.fontSize)
					
					if(res.data.length<=7){
						res.data.forEach(item=>{
							item.showNum=item.children.length;
							var div=document.createElement('div');
							div.style.width="fit-content"
							// var str='<span style="padding-right:10px;border-right:2px solid #000">'+item.label+'</span>';
							var str=""
							for(var i=0;i<item.children.length;i++){
								if(i==item.children.length-1){
									str+='<span style="padding:0 0 0 0">'+item.children[i].label+'</span>';
								}else{
									str+='<span style="padding:0 15px 0 0">'+item.children[i].label+'</span>';
								}
								div.innerHTML=str;
								document.body.appendChild(div)
								if(parseFloat(getComputedStyle(div).width)>widthVal){
									item.showNum=i;
									document.body.removeChild(div)								
									break
								}else{
									document.body.removeChild(div)
								}
							}

							// item.children.splice(item.showNum,0,{})
							// if(item.showNum<4){
							// 	item.children.splice(item.showNum,0,{})
							// }
							// var div2=document.createElement('div')
							// div2.style.width="fit-content"
							// var str2='<span style="padding-right:10px;border-right:2px solid #000">'+item.label+'</span>';
							// for(var i=item.showNum+1;i<item.children.length;i++){
							// 	str2+='<span style="padding:0 10px">'+item.children[i].label+'</span>';
							// 	div2.innerHTML=str2;
							// 	document.body.appendChild(div2)
							// 		console.log('00000',item.label,i)
							// 	if(parseFloat(getComputedStyle(div2).width)>widthVal){
							// 		item.showNum=i;
							// 		document.body.removeChild(div2)								
							// 		break
							// 	}else{
							// 		item.showNum=item.children.length;
							// 		document.body.removeChild(div2)
							// 	}
							// }
						})
					}else{
						res.data.forEach(item=>{
							item.showNum=item.children.length;
							var div=document.createElement('div');
							div.style.width="fit-content"
							var str='<span style="padding-right:10px;border-right:2px solid #000">'+item.label+'</span>';
							for(var i=0;i<item.children.length;i++){
								str+='<span style="padding:0 10px">'+item.children[i].label+'</span>';
								div.innerHTML=str;
								document.body.appendChild(div)
								if(parseFloat(getComputedStyle(div).width)>widthVal){
									item.showNum=i;
									document.body.removeChild(div)								
									break
								}else{
									document.body.removeChild(div)
								}
							}
						})
					}
					this.goodsTypeList=res.data;
					// this.clickLevelOne(res.data[0],0);
				}
			})
		},
		// 点击(移入)一级分类
		clickLevelOne(item,i){
			if(!this.isShowChild){
				this.isShowChild=true
			}
			this.activeIndex=item.id;
			this.activeIndex2='';
			// getGoodsTypeListChild({id:item.id}).then(res=>{
			// 	console.log("商品分类子列表",res);
			// 	if(res.code==200){
			// 		this.showTypeName=item.label;
			// 		this.showTypeChildren=res.data;
			// 	}
			// });

			this.showTypeName=item.label;
			this.showTypeChildren=item.children;

			// 获取明星品牌
			this.gainStarBrandList(item)
		},
		jumpToSearchOne(item){
			sessionStorage.removeItem('searchKeyword')
			sessionStorage.home_typeId=item.id;
			sessionStorage.home_level2TypeId=item.id;
			sessionStorage.home_typeName=item.label;
			this.$router.push({
				path:"merchandise",
				query:{fromHome:true}
			})
		},
		// 点击(移入)二级分类
		clickLevelTwo(child,j){
			console.log(child)
			if(!this.isShowChild){
				this.isShowChild=true
			}
			if(child.level==2){
				// this.activeIndex=i;
				this.activeIndex='';
				this.activeIndex2=child.id;
				this.showTypeName=child.label;
				this.showTypeChildren=child.children;
	
				this.gainStarBrandList(child);
			}else if(child.level==3){
				sessionStorage.removeItem('searchKeyword')
				sessionStorage.home_typeId=child.id;
				sessionStorage.home_typeName=child.label;
				this.$router.push({
					path:"merchandise",
					query:{fromHome:true}
				})
			}
		},
		jumpToSearchTwo(child,item){
			sessionStorage.removeItem('searchKeyword')
			sessionStorage.home_typeId=child.id;
			sessionStorage.home_level2TypeId=item.id;
			sessionStorage.home_typeName=child.label;
			this.$router.push({
				path:"merchandise",
				query:{fromHome:true}
			})
		},
		// 移入整行
		enterLine(item){
			if(!this.isShowChild){
				this.isShowChild=true
			}
			this.activeIndex=item.id;
			this.activeIndex2='';
			// getGoodsTypeListChild({id:item.id}).then(res=>{
			// 	console.log("商品分类子列表",res);
			// 	if(res.code==200){
			// 		this.showTypeName=item.label;
			// 		this.showTypeChildren=res.data;
			// 	}
			// });

			this.showTypeName=item.label;
			this.showTypeChildren=item.children;

			// 获取明星品牌
			this.gainStarBrandList(item)
		},
		// 点击明星品牌/品牌上新/加盟品牌
		clickStarBrand(item){
			sessionStorage.removeItem('searchKeyword')
            sessionStorage.brandId=item.brandId;
            this.$router.push({
                path:"/merchandise",
                query:{fromBrand:true}
            })
		},
		outInner($event){
			// console.log($event)
			// if($event.relatedTarget.className.indexOf("home")!=-1||$event.relatedTarget.className.indexOf("navBar")!=-1){
			// 	this.isShowChild=false
			// }
			var inner=document.querySelector(".inner");
			if(inner.contains($event.relatedTarget)){
				// console.log(1)
			}else{
				// console.log(2)
				this.isShowChild=false
			}
		},
		// 鼠标移出分类
		mouseoutInner(e){
			// if(e.target.className.indexOf("inner")!=-1){
			// 	this.isShowChild=false;
			// }
			// console.log(e)
			// e.stopImmediatePropagation();
			var inner=document.querySelector(".inner");
			if(inner.contains(e.target)){
				// console.log(1)
			}else{
				// console.log(2)
				this.isShowChild=false
			}
		},
		// 获取明星品牌列表
		gainStarBrandList(item){
			getBrandList__({
				goodsTypeId:item.id
			}).then(res=>{
				console.log("明星品牌列表",res);
				if(res.code==200){
					this.brandList=res.data;
				}
			})
		},
		// 获取优选套餐
		gainGoodsPackageList(){
			queryGoodsPackageList({
				spaceType:this.packageSpaceTypeValue
			}).then(res=>{
				console.log("优选套餐",res);
				if(res.code==200){
					this.goodsPackageList=res.data.splice(0,5)
				}
			})
		},
		// 点击优选套餐空间分类
		clickPackageSpaceType(item){
			this.packageSpaceTypeValue=item.dictValue;
			this.gainGoodsPackageList();			
		},
		// 获取加盟品牌
		gainJoinedBrandList(){
			getBrandPageList({
				page:1,
				pageSize:4,
			}).then(res=>{
				console.log("加盟品牌",res);
				if(res.code==200){
					this.joinedBrandList=res.data.rows;
				}
			})
		},
		// 获取热门买家秀
		gainHotBuyerShow(){
			getBuyersShowList__({
				page:1,
				size:6,
				hot:"Y"
			}).then(res=>{
				console.log("热门买家秀",res);
				if(res.code==200){
					this.hotBuyersShowLlist=res.data.rows
				}
			})
		},
		// 获取优选商品筛选条件
		gainPreferredGoodsSelect(){
			getPreferredGoodsSelect().then(res=>{
				console.log("优选商品筛选条件",res);
				if(res.code==200&&res.data){
					this.preferredGoodsSelect=res.data.selectList;
					this.preferredType=this.preferredGoodsSelect[0].value;
				}
				this.gainPreferredGoods();
			})
		},
		// 点击优选家具分类
		clickPreferredGoodsType(item){
			this.preferredType=item.value;
			this.gainPreferredGoods()
		},
		// 获取优选商品
		gainPreferredGoods(){
			getPreferredGoodsList({
				page:1,
				size:4,
				goodsTypes:this.preferredType,
			}).then(res=>{
				console.log("优选商品列表",res);
				if(res.code==200){
					this.preferredGoodsList=res.data.rows;
				}
			})
		},
		// 点击优选家具更多
		clickMorePreferred(){
			sessionStorage.isPreferredGoods=1;
			this.$router.push({
				path:"/merchandise",
				query:{isPreferred:true}
			})
		},
		// 品牌上新
		gainBrandNewly(){
			getBrandList__().then(res=>{
				console.log("品牌上新",res);
				if(res.code==200){
					this.brandNewlyList=res.data.splice(0,5)
				}
			})
		},
		// 获取空间类型
		gainSpaceTypes(){
			getDictTypeList({
				dictType:"SpaceType"
			}).then(res=>{
				console.log("空间类型",res);
				if(res.code==200){
					this.spaceTypeList=res.data;
					this.houseSpaceTypeValue=this.spaceTypeList[0].dictValue;
					this.packageSpaceTypeValue=this.spaceTypeList[0].dictValue;
				}
				this.gainGoodsPackageList();
				this.gainHousePackageList();				
			})
		},
		// 点击全屋定制空间类型
		clickHouseSpace(item){
			this.houseSpaceTypeValue=item.dictValue;
			this.gainHousePackageList();
		},
		// 获取全屋定制列表
		gainHousePackageList(){
			getHousePackagePreferred({
				spaceType:this.houseSpaceTypeValue
			}).then(res=>{
				console.log("全屋定制列表",res);
				if(res.code==200){
					this.housePackageList=res.data;
					var that=this

					this.$nextTick(()=>{
						$(function(){ $(".flipster").flipster({ style: 'carousel', start: 0,enableMousewheel:false,buttons: true, 
							clickCurrentCall:(housePackageId)=>{
								that.$router.push({
									path:"/houseComboDetail",
									query:{housePackageId:housePackageId}
								})
							}
						}); });
					})
				}
			})
		},
		// 点击我要定制
		wantToCustom(){
			var dom=document.querySelector(".flip-current")
			// console.log($(dom).data('packid'))
			this.$router.push({
				path:"/houseComboDetail",
				query:{housePackageId:$(dom).data('packid')}
			})
		},
		// 获取高定专区商品类型
		gainCoutureGoodsType(){
			getDictTypeList({
				dictType:"GoodsType"
			}).then(res=>{
				console.log("高定专区商品类型",res);
				if(res.code==200){
					this.coutureGoodsTypeList=res.data.filter(item=>{
						return ["沙发","茶几","电视柜","床","餐桌","书柜"].includes(item.dictLabel)
					});
					this.coutureGoodsTypeValue=this.coutureGoodsTypeList[0].dictValue;
				}
				this.gainCoutureGoods();
			})
		},
		// 点击高定专区商品类型
		clickCoutureType(item){
			this.coutureGoodsTypeValue=item.dictValue;
			this.gainCoutureGoods();
		},
		// 获取高定专区商品
		gainCoutureGoods(){
			getCoutureGoodsList({
				pageNum:this.couturePage,
				pageSize:this.coutureSize,
				goodsTypes:this.coutureGoodsTypeValue
			}).then(res=>{
				console.log("高定专区商品列表",res);
				if(res.code==200){
					// this.coutureGoodsList=res.data.rows.splice((this.couturePage-1)*4,4);
					this.coutureGoodsList=res.data.rows
				}
			})
		},
		// 点击高定专区商品
		clickCoutureGoods(item){
			this.$router.push({
				path:"/furnitureDetail",
				query:{goodsId:item.goodsId}
			})
		},
		// 点击切换高定页码
		clickCoutureSwi(i){
			if(i==1){
				if(this.couturePage>1){
					this.couturePage--;
					this.gainCoutureGoods();
				}else{}
			}else if(i==2){
				if(this.couturePage<4){
					this.couturePage++;
					this.gainCoutureGoods();					
				}else{}
			}
		},
		// 点击优选商品
		clickPreferredGoods(item){
			this.$router.push({
				path:"/furnitureDetail",
				query:{goodsId:item.goodsId}
			})
		},
		// 点击优选套餐
		clickPreferredPackage(item){
			this.$router.push({
				path:"/comboDetail",
				query:{goodsPackageId:item.goodsPackageId}
			})
		},
		// 点击优选套餐
		clickHousePackage(item){
			this.$router.push({
				path:"/houseComboDetail",
				query:{housePackageId:item.housePackageId}
			})
		},
		// 点击热门买家秀
		clickCase(item){
			this.$router.push({
                path:"/caseDetail",
                query:{buyersShowId:item.buyersShowId}
            })
		}
	},
	created() {
		this.gainWebsiteSetting();
		this.gainGoodsTypeTree();
		this.gainJoinedBrandList();
		this.gainHotBuyerShow();
		this.gainPreferredGoodsSelect();
		this.gainBrandNewly();
		this.gainSpaceTypes();
		this.gainCoutureGoodsType();
	},
	mounted() {
		// setTimeout(()=>{
		// 	var that=this
		// 	$(function(){ $(".flipster").flipster({ style: 'carousel', start: 0,enableMousewheel:false,buttons: true,
		// 		clickCurrentCall:(housePackageId)=>{
		// 			that.$router.push({
		// 				path:"/houseComboDetail",
		// 				query:{housePackageId:housePackageId}
		// 			})
		// 		}
		// 	}); });
		// },1000)

		// var liveCard=document.querySelector(".liveCard");
		// if(!liveCard){
		// 	return
		// }
		// var style=getComputedStyle(liveCard);
		// var top=parseFloat(style.top);
		// var shakeTime=null;
		// document.querySelector("#app").addEventListener("scroll",(e)=>{
		// 	// console.log(12345)

		// 	if(shakeTime){
		// 		clearTimeout(shakeTime)
		// 	}
		// 	shakeTime=setTimeout(()=>{
				
		// 		// console.log(top)
		// 		// console.log(e.target.scrollTop)
		// 		if(e.target.scrollTop>=550){
		// 			liveCard.style.top=e.target.scrollTop+50+'px';
		// 		}else{
		// 			liveCard.style.top=top+'px';
		// 		}
		// 		// console.log(liveCard.style)
		// 	},500)
		// })
	},
	watch:{
		
	}
}
</script>

<style lang="less" scoped>
.home{
	// height: 100%;
	overflow: auto;
	// padding-bottom: 50px;
	position: relative;
	// .liveCard{
	// 	position: absolute;
	// 	top: 551px;
	// 	left: calc(50% - 990px);
	// 	z-index: 1000;
	// 	background: #ccc;
	// 	width: 225px;
	// 	height: 383px;
	// 	box-shadow: 0px 2px 8px  rgba(0, 0, 0, 0.1);
	// 	border-radius: 10px;
	// 	transition: all 0.3s;
	// 	.playArea{
	// 		width: 100%;
	// 		height: 294px;
	// 		// background: #000;
	// 		border-radius: 10px 10px 0 0;
	// 	}
	// 	.watchNum{
	// 		position: absolute;
	// 		top: 12px;
	// 		left: 10px;
	// 		width: 107px;
	// 		height: 30px;
	// 		border-radius: 17px;
	// 		background: rgba(0, 0, 0, 0.4);
	// 		display: flex;
	// 		align-items: center;
	// 		.effect{
	// 			width: 30px;
	// 			height: 30px;
	// 			border-radius: 50%;
	// 			background: linear-gradient(144.34deg, rgba(255, 107, 142, 1) 0%, rgba(230, 0, 54, 1) 100%);
	// 		}
	// 		.num{
	// 			flex: 1;
	// 			height: 100%;
	// 			padding: 0 6px;
	// 			font-size: 14px;
	// 			font-weight: 400;
	// 			color: rgba(255, 255, 255, 1);
	// 			display: flex;
	// 			align-items: center;
	// 		}
	// 	}
	// 	.brandLogo{
	// 		position: absolute;
	// 		top: 270px;
	// 		left: 21px;
	// 		width: 48px;
	// 		height: 48px;
	// 		border-radius: 50%;
	// 		&>img{
	// 			width: 100%;
	// 			height: 100%;
	// 			border-radius: 50%;
	// 		}
	// 	}
	// 	.liveName{
	// 		margin-top: 29px;
	// 		font-size: 18px;
	// 		font-weight: 700;
	// 		color: rgba(56, 56, 56, 1);
	// 		padding: 0 10px;
	// 	}
	// 	.desc{
	// 		margin-top: 8px;
	// 		font-size: 16px;
	// 		font-weight: 400;
	// 		color: rgba(128, 128, 128, 1);
	// 		padding: 0 10px;
	// 	}
	// }
	&::-webkit-scrollbar{
		width: 0;
	}
	.banner{
		width: 100%;
		// height: 650px;
		height: 584px;
		// background: url("../assets/imgs/place1.png");
		background-size: 100% 100%;
		position: relative;
		.place{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
		}
		.homeBanner{
			height: 100%;
			/deep/.el-carousel__container{
				height: 100%;
				.el-carousel__item{
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
		.inner{
			position: absolute;
			top: 0;
			bottom: 0;
			left: calc(50% - 735px);
			right: 0;
			z-index: 10;
			height: 584px;
			// width: 1470px;
			width: 396px;
			display: flex;
			justify-content: space-between;
			&:hover{
				width: 1470px;
			}
			.bigKind{
				width: 396px;
				height: 100%;
				// border-radius: 6px;
				background: rgba(255, 255, 255, 0.9);
				box-sizing: border-box;
				padding: 20px 15px;
				overflow: auto;
				box-shadow: 0px 3px 6px  rgba(0, 0, 0, 0.15);
				&::-webkit-scrollbar{
					width: 0;
				}
				.line{
					font-size: 16px;
					color: rgba(128, 128, 128, 1);
					font-weight: 400;
					padding: 13px 5px;
					border-radius: 6px;
					// overflow: hidden;
					// text-overflow: ellipsis;
					// word-break: break-all;
					// word-wrap: break-word;
					// &:last-child{
					// 	padding-bottom: 0
					// }
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					max-height: calc(100% / 5)!important;
					min-height: calc(100% / 12)!important;
					&:hover{
						background: #f3f3f3;
					}
					span{
						padding: 0 10px;
						cursor: pointer;
						&:hover{
							color: rgba(37, 126, 93, 1)!important;
						}
						&.active{
							color: rgba(37, 126, 93, 1)!important;
						}
					}
					span.overName{
						color: #333;
						font-weight: 700;
						padding-right: 10px;
						padding-left: 0;
						border-right: 2px solid rgba(0, 0, 0, 1);
						&:hover{
							border-right-color: rgba(37, 126, 93, 1);
						}
						&.active{
							border-right-color: rgba(37, 126, 93, 1);
						}
					}
					.placehold{
						width: 75px;
					}
					&.wrap{
						display: block;
						.wrapLine{
							display: flex;
							align-items: center;
							margin-top: 12px;
							&>span{
								padding-left: 0;
								padding-right: 15px;
								&:last-child{
									padding-right: 0;
								}
							}
						}
					}
					
					// &:hover{
					// 	color: rgba(37, 126, 93, 1);
					// 	span.overName{
					// 		color: rgba(37, 126, 93, 1);
					// 		border-right-color: rgba(37, 126, 93, 1);
					// 	}
					// }
					// &.active{
					// 	// color: rgba(0, 186, 173, 1);
					// 	color: rgba(37, 126, 93, 1);
					// 	span.overName{
					// 		color: rgba(37, 126, 93, 1);
					// 		border-right-color: rgba(37, 126, 93, 1);
					// 	}
					// }
				}
			}
			.kind{
				flex: 1;
				height: 100%;
				// margin-left: 14px;
				margin-left: 20px;
				// border-radius: 6px;
				background: rgba(255, 255, 255, 0.9);
				box-sizing: border-box;
				padding: 40px  20px;
				display: flex;
				box-shadow: 0px 3px 6px  rgba(0, 0, 0, 0.15);
				.plateName{
					padding-bottom: 10px;
					border-bottom: 1px solid rgba(224, 224, 224, 1);
					display: flex;
					align-content: center;
					font-size: 16px;
					font-weight: 400;
					color: #333;
					margin-bottom: 30px;
					cursor: pointer;
					&>span{
						margin-right: 13px;
					}
					&>img{
						width: 15px;
						height: 8px;
						margin-top: 7px;
					}
				}
				.subKind{
					width: calc(50% - 26px);
					height: 100%;
					.contains{
						height: calc(100% - 64px);
						overflow: auto;
						&::-webkit-scrollbar{
							width: 0;
						}
						.containsInner{
							display: flex;
							flex-wrap: wrap;
							.item{
								width: 100px;
								// margin-right: 28px;
								margin: 0 14px;
								margin-bottom: 15px;
								cursor: pointer;
								&:hover{
									filter:brightness(0.7)
								}
								&:nth-child(4n){
									// margin-right: 0
								}
								.pic{
									width: 100px;
									height: 100px;
									margin-bottom: 10px;
									border-radius: 50%;
									background: rgba(238, 238, 238, 1);
									display: flex;
									align-items: center;
									justify-content: center;
									img{
										max-width: 67.62px;
									}
								}
								p{
									text-align: center;
									overflow: hidden;
									text-overflow: ellipsis;
									font-size: 16px;
									font-weight: 400;
									color: #333;
								}
							}
						}
					}
				}
				.starBrand{
					width: calc(50% - 30px);
					margin-left: 56px;
					.contains{
						height: calc(100% - 64px);
						overflow: auto;
						&::-webkit-scrollbar{
							width: 0;
						}
						.containsInner{
							display: flex;
							flex-wrap: wrap;
							.item{
								width: 132px;
								height: 71px;
								margin-right: 23px;
								margin-bottom: 23px;
								cursor: pointer;
								box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
								position: relative;
								&:hover{
									// filter:brightness(0.7);
									.text{
										display: flex;
									}
								}
								&:nth-child(3n){
									margin-right: 0;
								}
								img{
									width: 132px;
									height: 71px;
									border-radius: 10px;
									background: #fff;
								}
								.text{
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									border: 1px solid #257e5d;
									border-radius: 10px;
									color: #257e5d;
									display: flex;
									align-items: center;
									justify-content: center;
									background: #fff;
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
	.prefecture{
		.area{
			margin-top: 86px;
			margin-top: 43px;
			margin-top: 60px;
			&.bk{
				background: rgba(247, 247, 247, 1);
				padding: 90px 0 60px 0;
			}
			.nameLine{
				display: flex;
				align-items: center;
				// justify-content: space-between;
				justify-content: center;
				.areaName{
					position: relative;
					// padding-bottom: 13px;
					&>img{
						width: 59px;
						height: 75px;
					}
					&>span{
						font-size: 20px;
						font-weight: 400;
						color: #333;
						position: relative;
						display: flex;
					}
					.effect{
						position: absolute;
						left: -20px;
						bottom: 2px;
						bottom: -8px;
						display: none;
						&.right{
							left: auto;
							right: -10px;
						}
					}
					.bottomline{
						position: absolute;
						left: 0;
						bottom: 0;
						bottom: -10px;
						height: 2px;
						background: rgba(37, 126, 93, 1);
						width: 102px;
						display: none;
					}
					.circle{
						position: absolute;
						left: 10px;
						top: calc(50% - 8px);
						transform: translate(-50%,-50%);
						width: 59px;
						height: 59px;
						border-radius: 50%;
						// background: rgba(72, 217, 164, 0.15);
					}
				}
				.more{
					cursor: pointer;
					width: 73px;
					height: 32px;
					border-radius: 24px;
					background: rgba(229, 229, 229, 1);
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					font-weight: 500;
					color: #333;
					padding-left: 5px;
					padding-bottom: 1px;
					i{
						// margin-left: 4px;
						// margin-top: 2px;
					}
					&:hover{
						background: rgba(225, 242, 236, 1);
						color: rgba(37, 126, 93, 1);
					}
				}
				.divider_l{
					flex: 1;
					height: 2px;
					margin-right: 44px;
				}
				.divider_r{
					flex: 1;
					height: 2px;
					margin-left: 44px;
				}
			}
			.tabLine{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 34px;
				// margin-top: 17px;
				margin-top: 30px;
				.tabs{
					height: 37px;
					display: flex;
					&>div{
						height: 100%;
						padding: 0 32px;
						display: flex;
						align-items: center;
						font-size: 18px;
						font-weight: 400;
						color: #333;
						// border: 3px solid transparent;
						border: 1px solid rgba(196, 196, 196, 1);
						box-sizing: border-box;
						cursor: pointer;
						position: relative;
						margin-right: 34px;
						&:last-child{
							margin-right: 0;
						}
						&.active{
							color: rgba(37, 126, 93, 1);
							border-color: rgba(37, 126, 93, 1);
							border-width: 2px;
							.border_effect{
								display: none;
							}
						}
						.border_effect{
							height: calc(100% + 6px);
							width: calc(100% + 6px);
							position: absolute;
							left: -3px;
							top: -3px;
							&>div{
								transition: all 0.3s linear;
							}
							.border_top{
								position: absolute;
								top: 0;
								right: 0;
								height: 3px;
								width: 0;
								background: rgba(37, 126, 93, 1)
							}
							.border_right{
								position: absolute;
								bottom: 0;
								right: 0;
								height: 0;
								width: 3px;
								background: rgba(37, 126, 93, 1)
							}
							.border_bottom{
								position: absolute;
								bottom: 0;
								left: 0;
								height: 3px;
								width: 0;
								background: rgba(37, 126, 93, 1)
							}
							.border_left{
								position: absolute;
								top: 0;
								left: 0;
								height: 0;
								width: 3px;
								background: rgba(37, 126, 93, 1)
							}
						}
						&:hover{
							.border_top{
								width: 100%;
							}
							.border_right{
								height: 100%;
							}
							.border_bottom{
								width: 100%;
							}
							.border_left{
								height: 100%;
							}
						}
					}
				}
				.more{
					cursor: pointer;
					width: 73px;
					height: 32px;
					border-radius: 24px;
					background: rgba(229, 229, 229, 1);
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					font-weight: 500;
					color: #333;
					padding-left: 5px;
					padding-bottom: 1px;
					i{
						// margin-left: 4px;
						// margin-top: 2px;
					}
					&:hover{
						background: rgba(225, 242, 236, 1);
						color: rgba(37, 126, 93, 1);
					}
				}
			}
			.couture,.furniture{
				width: 100%;
				margin-top: 30px;
				.list{
					position: relative;
					.leftClick{
						position: absolute;
						left: -92px;
						top: 123px;
						cursor: pointer;
					}
					.rightClick{
						position: absolute;
						right: -92px;
						top: 123px;
						cursor: pointer;
					}
					.listInner{
						width: 100%;
						display: flex;
						// justify-content: space-between;
						&>div{
							margin-right: 27px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
			}
			.furniture{
				.card340_394{
					background: #fff;
				}
			}

			.brand{
				margin-top: 30px;
				display: grid;
				grid-template-rows: 330px 330px;
				grid-template-columns: 447px 350px 202px 350px;
				grid-row-gap: 27px;
				justify-content: space-between;
				// grid-template-areas: "a b c"
				// "a d e";
				.item{
					background: #ccc;
					position: relative;
					overflow: hidden;
					&:hover{
						img.back{
							width: calc(100% + 20px);
							height: calc(100% + 20px);
							// transform: translate(-10px,-10px);
						}
					}
					img.back{
						width: 100%;
						height: 100%;
						border-radius: 6px;
						object-fit: cover;
						transition: width 0.3s , height 0.3s;

						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%,-50%)
					}
					img.logo{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						width: 260px;
						height: 144px;
						border-radius: 10px;
						opacity: 0.8;
					}
					&:nth-child(1){
						grid-row-start: 1;
						grid-row-end: 3;
						grid-column-start: 1;
						grid-column-end: 2;
					}
					&:nth-child(2){
						grid-column-start: 2;
						grid-column-end: 4;
					}
					&:nth-child(3){
						grid-column-start: 4;
						grid-column-end: 5;
					}
					&:nth-child(5){
						grid-column-start: 3;
						grid-column-end: 5;
					}
				}
			}

			.customization{
				margin-top: 30px;
				background: rgba(37, 126, 93, 0.1);
				padding: 40px 0;
				.tabs{
					margin-top: 30px;
					height: 37px;
					display: flex;
					justify-content: center;
					&>div{
						height: 100%;
						padding: 0 32px;
						display: flex;
						align-items: center;
						font-size: 18px;
						font-weight: 400;
						color: #333;
						// border: 3px solid transparent;
						// border: 1px solid rgba(196, 196, 196, 1);
						box-sizing: border-box;
						cursor: pointer;
						position: relative;
						margin-right: 34px;
						border-radius: 6px;
						background: #fff;
						color: rgba(37, 126, 93, 0.7);
						&:last-child{
							margin-right: 0;
						}
						&::after{
							content: "";
							position: absolute;
							top: calc(100% - 5px);
							left: 50%;
							transform: translateX(-50%);
							width: 0;
							height: 0;
							border-top: 15px solid rgba(37, 126, 93, 1);
							border-left: 12px solid transparent;
							border-right: 12px solid transparent;
							display: none;
						}
						&.active{
							background: rgba(37, 126, 93, 1);
							color: rgba(255, 255, 255, 1);
							&::after{
								display: block;
							}
						}
						&:hover{
							background: rgba(37, 126, 93, 1);
							color: rgba(255, 255, 255, 1);
							// &::after{
							// 	display: block;
							// }
						}
						// &.active{
						// 	color: rgba(37, 126, 93, 1);
						// 	border-color: rgba(37, 126, 93, 1);
						// 	border-width: 2px;
						// 	.border_effect{
						// 		display: none;
						// 	}
						// }
						// .border_effect{
						// 	height: calc(100% + 6px);
						// 	width: calc(100% + 6px);
						// 	position: absolute;
						// 	left: -3px;
						// 	top: -3px;
						// 	&>div{
						// 		transition: all 0.3s linear;
						// 	}
						// 	.border_top{
						// 		position: absolute;
						// 		top: 0;
						// 		right: 0;
						// 		height: 3px;
						// 		width: 0;
						// 		background: rgba(37, 126, 93, 1)
						// 	}
						// 	.border_right{
						// 		position: absolute;
						// 		bottom: 0;
						// 		right: 0;
						// 		height: 0;
						// 		width: 3px;
						// 		background: rgba(37, 126, 93, 1)
						// 	}
						// 	.border_bottom{
						// 		position: absolute;
						// 		bottom: 0;
						// 		left: 0;
						// 		height: 3px;
						// 		width: 0;
						// 		background: rgba(37, 126, 93, 1)
						// 	}
						// 	.border_left{
						// 		position: absolute;
						// 		top: 0;
						// 		left: 0;
						// 		height: 0;
						// 		width: 3px;
						// 		background: rgba(37, 126, 93, 1)
						// 	}
						// }
						// &:hover{
						// 	.border_top{
						// 		width: 100%;
						// 	}
						// 	.border_right{
						// 		height: 100%;
						// 	}
						// 	.border_bottom{
						// 		width: 100%;
						// 	}
						// 	.border_left{
						// 		height: 100%;
						// 	}
						// }
					}
				}
				.showArea{
					margin-top: 40px;
					height: 702px;
					.goodsEmpty{
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						font-size: 14px;
						color: #666;
						background: #fff;
						&>img{
							width: 500px;
						}
					}
				}
				.btns{
					margin-top: 50px;
					text-align: center;
					.wantTo{
						background: rgba(37, 126, 93, 1);
						color: #fff;
						width: 260px;
						height: 68px;
						font-size: 20px;
						font-weight: 400;
						border-radius: 0;
					}
					.knowMore{
						background: transparent;
						border-color: rgba(37, 126, 93, 1);
						color: rgba(37, 126, 93, 1);
						width: 260px;
						height: 68px;
						font-size: 20px;
						font-weight: 400;
						border-radius: 0;
						margin-left: 25px;
					}
				}
			}
			.pack{
				margin-top: 30px;
				display: grid;
				grid-template-rows: 466px 466px;
				grid-template-rows: 466px;
				grid-template-columns: repeat(3,455px);
				grid-row-gap: 30px;
				justify-content: space-between;
				.item{
					background: rgba(245, 245, 245, 1);
					&:hover{
						.img>img{
							width: calc(100% + 20px);
							height: 370px;
						}
					}
					.img{
						width: 100%;
						height: 350px;
						position: relative;
						overflow: hidden;
						border-radius: 6px 6px 0 0;
						&>img{
							width: 100%;
							height: 350px;
							object-fit: cover;
							// background: #eee;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);
							transition: all 0.3s;
							border-radius: 6px 6px 0 0;
						}
					}
					&>.text{
						height: 105px;
						padding: 0 20px;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						// background: #eee;
						p:nth-child(1){
							font-size: 18px;
							font-weight: 700;
							color: rgba(56, 56, 56, 1);
							margin-bottom: 0;
						}
						p:nth-child(2){
							font-size: 24px;
							font-weight: 700;
							color: rgba(56, 56, 56, 1);
							&>span{
								font-size: 20px;
								font-weight: 400;
							}
						}
					}
					&:nth-child(1){
						grid-row-start: 1;
						grid-row-end: 2;
						grid-column-start: 1;
						grid-column-end: 3;
						position: relative;
						&:hover{
							.img>img{
								width: calc(100% + 20px);
								height: calc(100% + 20px);
							}
						}
						.img{
							width: 100%;
							height: 100%;
							position: relative;
							overflow: hidden;
							border-radius: 6px;
							&>img{
								width: 100%;
								height: 100%;
								object-fit: cover;
								// background: #eee;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%,-50%);
								transition: all 0.3s;
								border-radius: 6px;
							}
						}
						.text{
							display: block;
							height: auto;
							position: absolute;
							left: 0;
							bottom: 0;
							padding: 30px;
							width: 100%;
							box-sizing: border-box;
							background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(204, 204, 204, 0) 100%);
							p:nth-child(1){
								font-size: 18px;
								font-weight: 700;
								color: rgba(255, 255, 255, 1);
								margin-bottom: 0;
							}
							p:nth-child(2){
								margin-top: 15px;
								font-size: 24px;
								font-weight: 700;
								color: rgba(255, 255, 255, 1);
								&>span{
									font-size: 20px;
									font-weight: 400;
								}
							}
						}
					}
				}
			}
			.buyersShow{
				margin-top: 30px;
				display: grid;
				// grid-template-rows: 473px 473px;
				grid-template-rows: 473px;
				grid-template-columns: repeat(3,460px);
				grid-row-gap: 30px;
				justify-content: space-between;
				.item{
					width: 100%;
					cursor: pointer;
					&:hover{
						.img>img{
							width: calc(100% + 20px);
							height: calc(100% + 20px);
						}
					}
					&>.img{
						width: 100%;
						height: 370px;
						border-radius: 10px;
						overflow: hidden;
						position: relative;
						&>img{
							width: 100%;
							height: 100%;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);
							transition: all 0.3s;
						}
						.user{
							position: absolute;
							left: 0;
							bottom: 0;
							// background: rgba(255, 255, 255, 0.3);
							width: 100%;
							height: 82px;
							border-radius: 0px 0px 10px 10px;
							background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
							display: flex;
							align-items: center;
							padding: 0 15px;
							&>img{
								width: 46px;
								height: 46px;
								border-radius: 50%;
							}
							&>span{
								margin-left: 10px;
								font-size: 20px;
								font-weight: 400;
								letter-spacing: 0px;
								color: rgba(255, 255, 255, 1);
							}
						}
					}
					.text{
						height: 103px;
						// padding: 20px 0;
						// box-sizing: border-box;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						&>p:nth-child(1){
							font-size: 20px;
							font-weight: 700;
							color: rgba(56, 56, 56, 1)
						}
						&>p:nth-child(2){
							font-size: 20px;
							font-weight: 700;
							color: rgba(128, 128, 128, 1);
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
			.joinedBrand{
				width: 100%;
				margin-top: 30px;
				.list{
					position: relative;
					.leftClick{
						position: absolute;
						left: -92px;
						top: 123px;
						cursor: pointer;
					}
					.rightClick{
						position: absolute;
						right: -92px;
						top: 123px;
						cursor: pointer;
					}
					.listInner{
						width: 100%;
						display: flex;
						justify-content: space-between;
						.item{
							width: 340px;
							height: 394px;
							cursor: pointer;
							&:hover{
								.cover{
									img{
										width: calc(100% + 20px);
										height: calc(100% + 20px);
									}
								}
							}
							.cover{
								width: 100%;
								height: 280px;
								overflow: hidden;
								position: relative;
								&>img{
									width: 100%;
									height: 100%;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%,-50%);
									transition: all 0.3s;
									object-fit: cover;
								}
							}
							img.logo{
								width: 100%;
								height: 114px;;
								background: #fff;
								object-fit: cover;
							}
						}
					}
				}
			}
		}
	}
}
</style>
